.event-details__wrapper {
    display: flex;
    flex-direction: column;
    width: 80vw;
    margin-top: 10px;
    border: black solid 1px;
    border-radius: 5px;
    background-color: lightgray;
    color: black;
}
.event-details__graphic-wrapper {
    width: 80vw;
    padding-top: 10px;
    text-align: center;
}
.event-details__graphic-image {
    max-width: 80vw;
    text-align: center;
    // padding-left: 5vw;
}
.event-details__church-info {
    background-color: lightgray;
    color: black;
}
.event-details__church-name {
    font-size: 1.3em;
    padding-left: 5vw;
}
.event-details__church-street {
    // padding-left: 10vh;
    padding-left: 5vw;
}
.event-details__city-state-postal {
    padding-left: 5vw;
}
.event-details__event-date {
    padding: 10px 0 0 0;
    width: 80vw;
    text-align: center;
    // border: black solid 1px;
    font-weight: bold;
    font-size: 1.3em;
}
.event-details__event-time {
    // color: white;
    flex-grow: 1;
    width: 70vw;
    text-align: center;
}

.event-details__event-message {
    max-width: 80vw;
    text-align: center;
    // color: white;
    padding: 10px 20px;
}

//=====================
.eventdetailswrapper {
    text-align: center;
    // padding: 1rem;
    // margin: 0.5rem 0;
    margin-top: 10px;
    padding-top: 10px;
    width: 80%;
    // align-items: center;
    background: #286DA8;
    border: black solid 1px;
    color: white;
}
.event_graphics {
    width: 100%;
    text-align: center;
}
.event_image {
    width: 80%;
}
.church_info {
    text-align: left;
    font-family: sans-serif;
    margin-top: 10px;
    margin-left: 10%;
    margin-bottom: 15px;
    font-size: 1.2em;
}
.church_name {
    align-items: left;
    font-size: 1.4em;
    font-weight: bold;
}
.event_date {
    font-size: 2em;
    font-weight: bold;
}
.event_date_time {
    font-size: 1em;
    font-weight: bold;
    margin-bottom: 10px;
}
.eventmessage {
    margin-bottom: 25px;
    padding: 0px 5px;
}
