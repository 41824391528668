.success-message__wrapper {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 60vw;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    // margin: 20px;
    border: black solid 1px;
    border-radius: 5px;
    background-color: lightgray;
}
.success-message__header {
    background-color: #4484CE;
    color: white;
    text-align: center;
    padding: 3px 0px;
    border-bottom: black solid 1px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
.success-message__message {
    padding: 10px;
    text-align: center;
}
.success-message__button-wrapper {
    text-align: center;
}
.success-message__ok-button {
    padding: 2px 5px;
    margin: 5px 0px;
}
