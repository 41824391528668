.event-listing-component__event-box {
    display: flex;
    flex-direction: column;
    width: 75vw;
    margin-left: 2.5vw;
    margin: 5px 2.5vw;
    background-color: #4484CE;
}
.event-listing-component__date-box {
    background-color: #286DA8;
    color: white;
    font-weight: bold;
    padding: 3px 0px;

    text-align: center;
    border-top: black solid 1px;
    border-left: black solid 1px;
    border-right: black solid 1px;
    border-bottom: black double 1px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
.event-listing-component__address-box {
    background-color: lightgray;
    color: black;
    border-top: black solid 1px;
    border-left: black solid 1px;
    border-right: black solid 1px;
    border-bottom: black double 1px;
}
.event-listing-component__church-name {
    font-weight: bold;
    font-size: 1.1em;
    text-align: center;
}
.event-listing-component__street {
    text-align: center;
}
.event-listing-component__city-state {
    text-align: center;
}
.event-listing-component__postal-code {
    text-align: center;
}
.event-listing-component__view-details-box {
    background-color: #4484CE;
    text-align: center;
    padding: 3px 0px;
    border-top: black solid 1px;
    border-left: black solid 1px;
    border-right: black solid 1px;
    border-bottom: black double 1px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}
.event-listing-component__view-details-text {
    text-decoration: none;
    color: yellow;
}

// OLD STUFF

.event-box {
    text-align: center;
    // padding: 1rem;
    // margin: 0.5rem 0;
    padding-top: 0px;
    width: 80vw;
    // align-items: center;
    background-color: url('https://pate-images.s3.amazonaws.com/background.jpg');
    // background: #286DA8;
    color: white;
}
@media only screen and (max-width: 376px) {
    .event-box {
        width: 80vw;
    }
}
.event-listing__date-box {
    background-color: #4484CE;
    border-top: black solid 1px;
    border-left: black solid 1px;
    border-right: black solid 1px;
    border-bottom: black double 1px;
    margin-top: 10px;
    padding: 2px 0;
}
.event-location {
    font-size: 1.2em;
    font-weight: bold;
}
.event-listing__address-box {
    background-color: lightgray;
    color: black;
    border: black solid 1px;
}
.address {
    padding-left: 10px;
    border-left: black solid 1px;
    border-right: black solid 1px;
    padding-bottom: 10px;
}
.event-street {
    padding-left: 10px;
}
.event-city-state {
    padding-left: 10px;
}
.event-postalcode {
    padding-left: 10px;
}
.linkbutton {
    background-color: #4484CE;
    // margin-bottom: 10px;
    padding-bottom: 10px;
    padding-top: 10px;
    border-left: black solid 1px;
    border-bottom: black solid 1px;
    border-right: black solid 1px;
}
