.serve-page__event-list-header {
    text-align: center;
    font-weight: bold;
    font-size: 1.4em;
    background-color: #286DA8;
    color: white;
    // width: 80%;
    margin: 3px 0 0 0;
    padding: 5px 0px;
    text-shadow: 1px 1px 0px pink;
}
.event-list-item {
    margin: 3px 0;
}
.stateleadintro {
    padding: 0 15px 20px 15px;
}
.serve-component-lead__list-wrapper{
    background-color: lightgray;
    border: black solid 1px;
    border-radius: 5px;
    margin-left:5px;
    margin-right:5px;
}
.serve-component-lead__rally-list-item{
    display: flex;
    flex-direction: row;
    padding: 5px 0px 5px 2vw;
    
}
.serve-component-lead__rally-link{
    text-decoration: none;
    color: black;
    
}
.serve-component-lead__rally-date{
    color: black;
    min-width: 45px;
    text-align: center;
}
// .serve-lead-component__church-name{
//     color: black;
// }
.serve-component-lead__rep-name{
    color: blue;
    font-size: .8em;
    padding-top: 2px;
    padding-left: 1px;
}
.serve-component__rally-list-item {
    margin: 10px 20px 10px 20px;
}
.serve-component__rally-date {
    display: inline-block;
    padding-right: 5px;
}
.serve-component__rally-location {
    display: inline-block;
}
.sr-rally-link {
    text-decoration: none;
    &:focus,
    &:hover,
    &:visited,
    &:link,
    &:active {
        text-decoration: none;
        color: white;
    }
}
