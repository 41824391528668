.register-user-component__wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: lightgray;
    border-left: black solid 1px;
    border-bottom: black solid 1px;
    border-right: black solid 1px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    width: 80vw;
    padding-top:10px;
}
.register-user-component__input-line{
    display: flex;
    flex-direction: row;
    width: 80vw;
    padding-bottom: 5px;
}
.register-user-component__input-label{
    flex-grow: 1;
    color: black;
    width:30vw;
    text-align: right;
    padding-right: 5px;
    // padding:5px 3px;
}

.register-user-component__input-control{
    flex-grow: 1;
    width:50vw;
    text-align: left;
    // padding:5px;
    
}
.register-user-component__button-wrapper{
    padding-bottom:5px;
}
.register-user-component__register-button{
    background-color: yellow;
    padding:2px 5px;
    margin: 5px 0;
    border-radius: 5px;
}
