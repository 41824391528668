.registration__box {
    display: flex;
    margin-left: 10vw;
    align-items: center;
    flex-direction: column;
    width: 80vw;
    text-align: center;
}
.registration__header {
    font-size: 1.3em;
    font-weight: bold;
    padding: 5px 0px;
    background-color: #4717F6;
    width: 80vw;
    margin-top: 10px;
    color: white;
    border: black solid 1px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
.registration__section-header {
    font-size: 1.3em;
    font-weight: bold;
    padding-top: 5px;
    color: black;
}
.registration__detail-box {
    display: flex;
    padding: 15px 0px;
    background-color: lightgray;
    flex-direction: column;
    border-left: black solid 1px;
    border-right: black solid 1px;
}
.registration__data-row {
    display: flex;
    flex-direction: row;
    width: 80vw;
}
.registration__data-label {
    // border: red solid 1px;
    flex-grow: 1;
    color: black;
    width: 30vw;
    text-align: right;
    padding: 5px 3px;
}
.registration__data-input {
    // border: blue solid 1px;
    flex-grow: 1;
    width: 50vw;
    text-align: left;
    padding: 5px;
}
.registration__button-wrapper {
    padding-top: 5px;
    padding-bottom: 10px;
    background-color: #286DA8;
    border-left: black solid 1px;
    border-right: black solid 1px;
    width: 80vw;
}
.registration__button-update {
    padding: 2px 10px;
    background-color: yellow;
}
.registration__registration-list-box {
    width: 80vw;
    background-color: #286DA8;
    border-left: black solid 1px;
    border-right: black solid 1px;
    border-bottom: black solid 1px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    align-items: center;
    padding-bottom: 10px;
}
.registration__registration-list-header {
    width: 70vw;
    margin-left: 5vw;
    background: #4484CE;
    padding: 5px 0px;
    text-align: center;
    font-weight: bold;
    font-size: 1.3em;
    color: white;
    border-top: black solid 1px;
    border-left: black solid 1px;
    border-right: black solid 1px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
.registration__registration-data-row {
    width: 70vw;
    margin-left: 5vw;
    background: lightgray;
    color: black;
    padding-top: 5px;
    padding-bottom: 5px;
    border-right: black solid 1px;
    border-left: black solid 1px;
    border-bottom: black solid 1px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.serve-event-page__approval-true {
    font-weight: bold;
    color: white;
    background-color: green;
    padding: 1px;
}
.serve-event-page__approval-false {
    font-weight: bold;
    color: white;
    background-color: red;
    padding: 1px;
}
//==================================
.serve-event-content-wrapper {
    text-align: center;
    // padding: 1rem;
    // margin: 0.5rem 0;
    padding-top: 10px;
    width: 80%;
    height: auto;
    // align-items: center;
    background: lightgray;
    color: black;
}

.church-contact-header {
    font-weight: bold;
    margin-top: 10px;
}
.logistics-header {
    font-weight: bold;
    margin-top: 10px;
}
.meal-header {
    font-weight: bold;
    margin-top: 10px;
}
.tally-header {
    font-weight: bold;
    margin-top: 10px;
}
.sr-event-status-label .sr-event-status-tooltip {
    visibility: hidden;
    width: 120px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
}
.sr-event-status-label .sr-event-status-tooltip::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}
.sr-event-status-label:hover .sr-event-status-tooltip {
    visibility: visible;
    opacity: 1;
}
.serve-item-none-to-report-message {
    margin-bottom: 10px;
}
.serve-event-register-item-wrapper {
    margin-bottom: 5px;
}
.serve-event-register-item-name {
    display: inline-block;
}
.serve-event-register-item-numbers {
    display: inline-block;
    padding-left: 10px;
}

.serve-event__delete-box {
    background: lightgray;
    width: 80%;
    text-align: center;
}
.serve-event__delete-box__horizontal-line {
    border-top: 3px dotted red;
}
.serve-event__delete-button {
    background-color: red;
    color: white;
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    font-size: 1em;
    margin: 10px 0px;
}
