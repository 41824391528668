.signin-page__signin-wrapper {
    display: flex;
    flex-direction: column;
    width: 80vw;
    margin-left: 10vw;
}

/* IPAD */
// @media only screen and (min-width: 501px) and (max-width: 768px) {
//     .signin-page_signin-wrapper {
//         width: 50%;
//     }
// }
.signin-page__title-box {
    background-color: #4717F6;
    border-top: black solid 1px;
    border-left: black solid 1px;
    border-right: black solid 1px;
    border-bottom: black double 1px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    margin-top: 5px;
    padding: 2px 0px;
    color: white;
    text-align: center;
    font-weight: bold;
}
.signin-page__signin-box {
    background-color: lightgray;
    border-left: black solid 1px;
    border-right: black solid 1px;
    border-bottom: black solid 1px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}
.signin-page__input-line {
    display: flex;
    flex-direction: row;
    padding-top: 5px;
    width: 80vw;
}
.signin-page__input-label {
    text-align: right;
    padding-right: 5px;
    width: 30vw;
    // border: blue solid 1px;
}
.signin-page__input-control {
    flex-grow: 1;
    text-align: left;
    // border: blue solid 1px;
}
.signin-page__button-wrapper {
    text-align: center;
    padding: 5px 0px;
}
.signin-page__signin-button {
    padding: 2px 5px;
    background: yellow;
    border-radius: 5px;
}
.signin-page__offer-box {
    // border: black solid 1px;
    margin-top: 5px;
    background-color: white;
    color: black;
    padding:3px 5px;
    border-radius: 5px;
    // text-align: center;
}
.signin-page__register-link {
    text-decoration: none;
    color: blue;
    font-weight: bold;
}

/* greater than mobile */
@media only screen and (min-width: 415px) and (max-width: 1024px) {
    .signin-page__signin-wrapper {
        width: 300px;
    }
    .signin-page__input-line {
        text-align: left;
        width: 250px;
    }
}
@media only screen and (min-width: 1025px) {
    .signin-page__signin-wrapper {
        width: 300px;
    }
    .signin-page__input-line {
        text-align: left;
        width: 250px;
    }
}

//old stuff
.signin-page-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.signin-wrapper {
    text-align: center;
    padding-left: 10px;
    // border-left: black solid 1px;
    // border-right: black solid 1px;
}
signin-page_signin-box {
    margin-right: 10px;
}
.signin-box {
    // text-align: center;
    // padding: 1rem;
    // margin: 0.5rem 0;
    text-align: center;
    padding-top: 0px;
    width: 300px;
    // align-items: center;
    background: #286DA8;
    color: white;
    margin: 10px;
    // margin-left: 20%;
    border: black solid 1px;
}
.signin-title-box {
    background-color: #4484CE;
    border-top: black solid 1px;
    border-left: black solid 1px;
    border-right: black solid 1px;
    border-bottom: black double 1px;
    text-align: center;
    color: white;
    // margin-top: 10px;
    // padding: 2px 0;
}
.signin-page_signin-form {
    margin: 10px 15% 0 0;
}
.signin-page_input-line {
    margin: 5px 0 5px 0;
}
.signin-page_input-label {
    display: inline-block;
    margin-right: 10px;
    color: black;
}
.signin-page_button-wrapper {
    text-align: center;
    margin-top: 5px;
    margin-bottom: 5px;
}
.signin-page_register-offer-wrapper {
    text-align: center;
    width: 100%;
    display: inline-block;
    padding-left: 0;
    margin-bottom: 5px;
    color: black;
}
.signin-page_register-link {
    text-decoration: none;
}
.signin-page_register-link :visited .signin-page_register-link :active {
    color: yellow;
}
.register-button {
    font-family: 'Abril Fatface', cursive;
    padding: 3px 10px;
}
.register-offer-wrapper {
    padding: 5px 10px;
    margin-bottom: 10px;
}
.form-component {
    border-right: black solid 1px;
    border-bottom: black solid 1px;
    border-left: black solid 1px;
    // color: white;
}
